<template>
  <AdminLayout>
    <div class="admin">

    </div>
  </AdminLayout>
</template>

<script>
import AdminLayout from '@components/AdminLayout'
export default {
  name: "Admin",
  components: { AdminLayout },
  data: () => ({
  }),

  methods: {
  },
}
</script>

<style lang="scss" scoped>
.admin {
  height: 100%;

  display: grid;
  align-items: center;
  justify-content: center;

}
</style>
