<template>
  <div class="item-screenshots">
    <div class="item-screenshots-container container">
      <div class="item-screenshots-title">Слайды</div>
      <div class="item-screenshots-list">
        <div
            class="item-screenshots-item item-screenshots-item--pc"
            :style="{backgroundImage: `url(${item.preview})`}"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'item',
  ],
}
</script>

<style lang="scss">
.item {
  &-screenshots {
    margin-top: 100px;
    padding: 100px 0;

    background: #202022;

    &-title {
      font-weight: bold;
      font-size: 22px;
      line-height: 30px;
    }

    &-list {
      margin-top: 48px;

      display: grid;
      grid-template-columns: 1fr;
    }

    &-item {
      background: white no-repeat center / cover;
      border-radius: 15px;

      &--pc {
        width: 100%;
        height: 645px;
      }
    }
  }
}
</style>
