<template>
  <div class="admin-menu">
    <div class="admin-menu-list">
      <router-link to="/admin" class="admin-menu-item">
        home
      </router-link>
      <router-link to="/admin/users" class="admin-menu-item">
        users
      </router-link>
      <router-link to="/admin/portfolio" class="admin-menu-item">
        portfolio
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminMenu",
}
</script>

<style lang="scss" scoped>
.admin-menu {
  position: fixed;
  top: 0;
  left: 0;

  height: 100vh;
  max-width: 100px;
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;

  background: #222;
  border-right: 1px solid #333;

  &-list {
    margin-top: 24px;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
  }

  &-item {
    & + & {
      margin-top: 16px;
    }
  }
}
</style>
