<template>
  <div class="admin-layout">
    <AdminMenu />

    <div class="admin-layout-content">
      <slot />
    </div>
  </div>
</template>

<script>
import AdminMenu from '@components/AdminMenu'

export default {
  name: "AdminLayout",

  components: { AdminMenu },
}
</script>

<style lang="scss" scoped>
.admin-layout {
  position: relative;


}
</style>
