<template>
  <AdminLayout>
    <div class="admin-portfolio">
      portfolio
    </div>
  </AdminLayout>
</template>

<script>
import { mapActions } from 'vuex'
import AdminLayout from '@components/AdminLayout'

export default {
  name: "Admin",
  components: { AdminLayout },
  data: () => ({
    isAuth: false,
  }),

  mounted() {
    this.fetch()
    // this.login()
  },

  methods: {
    ...mapActions({
      fetch: 'users/fetch',
    }),
    onSubmit() {
      console.log('test')
    },
  },
}
</script>

<style lang="scss" scoped>
.admin-portfolio {
  height: 100%;

  display: grid;
  align-items: center;
  justify-content: center;

  &-form {
    max-width: 320px;
    width: 100%;

    &-label {
      display: flex;
      flex-direction: column;

      & + & {
        margin-top: 8px;
      }

      input {
        margin-top: 4px;
      }
    }

    &-button {
      margin-top: 16px;

      display: none;
    }
  }
}
</style>
