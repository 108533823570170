<template>
  <div class="item">
    <div class="item-container container">

      <h1 class="item-title">{{ item.title }}</h1>

      <a
          :href="item.link"
          class="item-link"
          target="_blank"
      >{{ item.link }}</a>

      <router-link class="item-close" to="/" />

      <div class="item-row">
        <div class="item-descr">
          <div class="item-descr-heading">О проекте</div>

          <div class="item-descr-text">
            {{ item.textFull }}
          </div>

          <div class="item-tags">
            <div
                class="item-tag"
                v-for="tag in item.tags">
              {{ tag }}
            </div>
          </div>
        </div>

        <div />

        <div class="item-stack">
          <div class="item-stack-heading">Стэк</div>
          <div class="item-stack-text">{{ item.stack }}</div>
        </div>
      </div>
    </div>

    <Screenshots :item="item" />
  </div>
</template>

<script>
import Screenshots from './Item/Screenshots'
import { mapGetters } from 'vuex'

export default {
  components: { Screenshots },

  computed: {
    ...mapGetters('list', [
      'getItem',
    ]),
    item() {
      return this.getItem(this.$route.params.id)
    },
  },
}
</script>

<style lang="scss">
.item {
  &-container {
    padding: 100px 0 0;

    position: relative;
  }

  &-close {
    width: 48px;
    height: 48px;

    position: absolute;
    top: 100px;
    right: 0;
    z-index: 1;

    background: rgba(255, 255, 255, 0.2) url('~assets/svg/close.svg') no-repeat center / 24px;
    border-radius: 50%;

    cursor: pointer;

    @include hover();
  }

  &-row {
    display: grid;
    grid-template-columns: 7fr 1fr 4fr;
    gap: 30px;
  }

  &-title {
    font-size: 46px;
    line-height: 50px;
    font-weight: 900;
  }

  &-link {
    margin-top: 16px;

    display: inline-block;

    font-size: 12px;
    text-decoration: underline;
    opacity: .5;
    font-weight: 100;

    &:hover {
      text-decoration: none;
    }
  }

  &-tags {
    display: flex;
    flex-wrap: wrap;
  }

  &-tag {
    height: 29px;
    margin-top: 24px;
    padding: 5px 20px;

    font-size: 12px;
    line-height: 18px;

    border: 1px solid #ffffff;
    border-radius: 15px;

    & + & {
      margin-left: 8px;
    }
  }

  &-descr {
    margin-top: 100px;

    &-heading {
      margin-bottom: 10px;

      font-weight: bold;
      font-size: 22px;
      line-height: 30px;
    }

    &-text {
      line-height: 24px;
    }
  }

  &-stack {
    margin-top: 100px;

    &-heading {
      margin-bottom: 10px;

      font-weight: bold;
      font-size: 22px;
      line-height: 30px;
    }
  }
}
</style>
