<template>
  <div class="admin-login">
    <form
        class="admin-login-form"
        @submit.prevent="onSubmit">
      <label class="admin-login-form-label">
        <input
            v-model="form.login"
            placeholder="Login"
            type="text"
            name="login">
      </label>
      <label class="admin-login-form-label">
        <input
            v-model="form.password"
            placeholder="Password"
            type="password"
            name="password">
      </label>
      <button
          type="submit"
          class="admin-login-form-button">
        Авторизоваться
      </button>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { getToken } from '@/api'

export default {
  name: "Admin",

  data: () => ({
    isAuth: false,

    form: {
      login: 'test3',
      password: 'test3',
    },
  }),

  mounted() {
    if (this.isLogged) {
      this.$router.push('/admin')
    }
  },

  computed: {
    ...mapState({
      isLogged: s => s.users.isLogged,
    }),
  },

  watch: {
    isLogged(bool) {
      if (bool) this.$router.push('/admin')
    },
  },

  methods: {
    ...mapActions({
      fetch: 'users/fetch',
      create: 'users/create',
      login: 'users/login',
    }),
    onSubmit() {
      this.login({ form: this.form })
          .then(() => this.$router.push('/admin'))
    },
  },
}
</script>

<style lang="scss" scoped>
.admin-login {
  height: 100%;

  display: grid;
  align-items: center;
  justify-content: center;

  &-form {
    max-width: 320px;
    width: 100%;

    &-label {
      display: flex;
      flex-direction: column;

      & + & {
        margin-top: 8px;
      }

      input {
        margin-top: 4px;
      }
    }

    &-button {
      margin-top: 16px;

      display: none;
    }
  }
}
</style>
